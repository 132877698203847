@import "../../../styles/constants";

.profile {
  .profile-outer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .profile-image-outer {
    position: relative;
    width: 122px;
    height: 119px;

    .with-cursor {
      cursor: pointer;
      cursor: hand;
    }
  }

  .profile-image {
    width: 122px;
    height: 119px;
    background-size: cover;
    background-image: url("../../../assets/images/profile-icon.svg");
    clip-path: circle(60px at center);
    -webkit-clip-path: circle(60px at center);
  }

  .plus-icon {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #cacaca;
    border: 2px solid white;
    display: flex;
    justify-content: center;
    align-items: center;

    .fa-plus {
      color: white;
    }
  }

  .link-button {
    display: block;
    text-align: center;
    text-decoration: underline;
    margin-top: 10px;
    color: $purple-brown;
  }

  .desc-area {
    min-height: 250px;
  }
}
