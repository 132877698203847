@import "../../../styles/constants";

.chat-message, .chat-message-me {
  margin-top: 10px;
  display: flex;
  align-items: flex-start;

  .chat-message {
    padding: 10px;
    border-radius: 17px;
    min-height: 42px;
  }

  .chat-time {
    color: $brown-grey;
    font-size: 12px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    min-height: 42px;
    margin: 10px 0;
  }
}

.chat-message {
  .chat-message {
    background-color: #efefef;
    margin-left: 10px;
  }

  .chat-time {
    margin-left: 10px;
  }
}

.chat-message-me {
  flex-direction: row-reverse;

  .chat-message {
    background-color: $lightish-red;
    color: #FFF;
    margin-right: 10px;

    &.file {
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: center;

      a {
        text-align: center;
        color: #FFFFFF;
      }

      .link {
        margin-top: 10px;
        text-decoration: underline;
      }
    }
  }

  .chat-time {
    margin-right: 10px;
  }
}

.chat-file-image {
  max-width: 300px;
  width: 100%;
  height: auto;
}

.chat-file-document {
  width: 100px;
  height: auto;
}

