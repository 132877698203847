@font-face {
  font-family: "OpenSansRegular";
  src: url("../assets/fonts/OpenSans-Regular.woff2") format("woff2"),
  url("../assets/fonts/OpenSans-Regular.woff") format("woff"),
  url("../assets/fonts/OpenSans-Regular.ttf") format("truetype")
}

@font-face {
  font-family: "OpenSansBold";
  src: url("../assets/fonts/OpenSans-Bold.woff2") format("woff2"),
  url("../assets/fonts/OpenSans-Bold.woff") format("woff"),
  url("../assets/fonts/OpenSans-Bold.ttf") format("truetype")
}

@font-face {
  font-family: "OpenSansSemiBold";
  src: url("../assets/fonts/OpenSans-SemiBold.woff2") format("woff2"),
  url("../assets/fonts/OpenSans-SemiBold.woff") format("woff"),
  url("../assets/fonts/OpenSans-SemiBold.ttf") format("truetype")
}

@font-face {
  font-family: "FuturaBold";
  src: url("../assets/fonts/Futura-Bold.woff2") format("woff2"),
  url("../assets/fonts/Futura-Bold.woff") format("woff"),
  url("../assets/fonts/Futura-Bold.ttf") format("truetype")
}


@mixin opensans-regular {
  font-family: "OpenSansRegular", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

@mixin opensans-bold {
  font-family: "OpenSansBold", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

@mixin opensans-semibold {
  font-family: "OpenSansSemiBold", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

@mixin futura-bold {
  font-family: "FuturaBold", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
