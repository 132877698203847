@import "../../../styles/constants";
@import "../../../styles/typography";

.Home {
  .logo-outer {
    cursor: pointer;
  }

  .logo-title {
    width: 300px;
    height: auto;
    cursor: pointer;
    padding-bottom: 10px;
    margin-left: 20px;

    /* Portrait phones and smaller */
    @media (max-width: 768px) {
      margin-top: 20px;
      margin-left: 0;
    }
  }

  .explain {
    width: 42rem;
  }

  .status-container {
    @include futura-bold;
    font-size: 25px;
    color: $purple-brown;
    text-align: center;
    margin-bottom: 0;

    .count {
      color: $lightish-red;
    }
  }
}
