@import "../../../styles/constants";

.profile-container {
  min-width: 200px;

  @media only screen and (max-width: 600px) {
    min-width: 100px;
  }
}



.card {
  font-size: 16px;
  margin: 0 auto;
  height: 400px;
  max-width: 170px;
  cursor: pointer;

  .card-footer {
    background-color: #FFFFFF;
    border-top: none;
  }

  .online {
    background-color: $aquamarine;
    height:24px;
    border-radius:50%;
    width:24px;
    position: absolute;
    right: 25px;
    top: 25px;
  }

  .profile-image {
    width: 122px;
    height: 122px;
    background-size: cover;
    background-image: url("../../../assets/images/profile-icon.svg");
    //http://bizdenkimvar.com/uploads/1570643691182-947720.png
    clip-path: circle(60px at center);
    -webkit-clip-path: circle(60px at center);
    margin: 0 auto;
    margin-top: 10px;
  }
}


.card-img-top {
  padding: 1rem 1rem 0 1rem;
}

.card-title {
  &.h5 {
    color: $purple-brown;
    font-size: 16px;
    text-align: center;
  }
}

.card-text {
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 70px;
  overflow: hidden;
}

.pagination-outer {
  margin-top: 30px;
}
