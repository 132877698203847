@import "../../../styles/constants";

footer {
  background-color: $lightish-red;
  color: white;
  height: 23rem;
  width: 100%;
  display: flex;
  padding: 20px;
  margin-top: 100px;

  a {
    color: white;

    &:hover {
      color: darken(#FFF, 10);
    }
  }

  .social-outer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 2.5rem;
  }

  .social-wrapper {
    width: 14rem;
    display: flex;
    justify-content: space-around;
  }

  .market-wrapper {
    margin-top: 2em;
    width: 14rem;
    display: flex;
    justify-content: space-around;

    .icon {
      height: 3em;
      margin-left: 0.5em;
      margin-right: 0.5em;
    }
  }

  .copyright {
    margin-top: 2.5rem;
    text-align: center;
  }

  .logo {
    text-align: center;

    img {
      width: 3.3rem;
    }
  }
}
