@import "../../../styles/constants";
@import "../../../styles/typography";

.ConfirmDelete {
  .help-title {
    text-align: center;
    color: $lightish-red;
    font-size: 28px;
    @include futura-bold;
  }

  span {
    margin-left: 5px;
    line-height: 30px;
  }
}
