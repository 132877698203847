@import "../../../styles/constants";

.sign-up {
  .profile-outer {
    display: flex;
    justify-content: center;
    text-align: center;

    .image {
      position: relative;

      img {
        border-radius:50%;
        object-fit: cover;
        display: block;
        width: 120px;
        height: 120px;
      }
    }
  }

  .plus-icon {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #cacaca;
    border: 2px solid white;
    display: flex;
    justify-content: center;
    align-items: center;

    .fa-plus {
      color: white;
    }
  }

}
