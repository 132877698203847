.information {
  .help-title {
    margin-top: 80px;
    text-align: center;
  }

  span {
    margin-left: 20px;
    line-height: 30px;
    font-size: 20px;
  }
}
