@import "../../../styles/constants";
@import "../../../styles/typography";

.login-card {
  border: 1px solid $brown-grey-two;
  padding: 50px;
}

.login-top-nav {
  font-size: 16px;

  display: flex;
  justify-content: center;

  a {
    color: $purple-brown;
    border-bottom: 2px solid $brown-grey-two;
    @include opensans-semibold;
  }

  a:nth-child(2) {
    margin-left: 20px;
  }

  .active {
    @include opensans-bold;
    border-bottom: 2px solid $lightish-red;
  }
}

.login-link-button {
  display: block;
  text-align: left;
  text-decoration: underline;
  margin-top: 10px;
  color: $purple-brown;
}
