@import "../../../styles/typography";
.who-we-are{

  h1 {
    @include futura-bold;
    font-size: 45px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  p {
    font-size: 14px;
  }
}
