.quotes-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 13px;
  margin-top: 4rem;
  margin-bottom: 40px;

  .navigation {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .content {
    margin-left: 30px;
    margin-right: 30px;
    text-align: center;

    .quote {

    }

    .name {
      margin-bottom: 2rem;
    }

    .bottom-outer {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .bottom-inner {
      width: 15rem;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }

  }
}
