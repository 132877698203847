@import "../../../styles/constants";

.Search {
  .logo-title {

  }

  .explain {
    width: 40rem;
  }

}
