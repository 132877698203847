@import "../../../styles/constants";
@import "../../../styles/typography";

.Chat {
  .loading-indicator-container {
    width: 100%;
    text-align: center;
  }

  .chat {
    display: flex;
    border: 1px solid $brown-grey;
    max-height: 600px;
  }

  .previews {
    border-right: 1px solid $brown-grey;
    overflow-y: auto;
    overflow-x: hidden;
    flex-shrink: 0;
    max-width: 300px;

    @media only screen and (max-width: 600px) {
      max-width: 100%;
    }
  }

  .btn-menu {
    background-color: transparent !important;
  }

  .username {
    @include opensans-bold;
  }

  .chat-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    max-width: 100%;

    .chat-header {
      display: flex;
      flex-direction: row;
      background-color: #f8f9fa;
      padding-left: 10px;
      align-items: center;
      flex-shrink: 0;

      .offline, .online {
        width: 75px;
        height: 75px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
      }

      .online {
        background-color: #00e6a1;
        clip-path: circle(33px at center);
        -webkit-clip-path: circle(33px at center);
      }

      .profile-image {
        content: '';
        width: 60px;
        height: 60px;
        background: url("../../../assets/images/profile-icon.svg") no-repeat center;
        background-size: cover;
        clip-path: circle(30px at center);
        -webkit-clip-path: circle(30px at center);
        align-self: center;
      }

      .chat-detail {
        display: flex;
        flex-grow: 1;
        margin-left: 10px;
        flex-direction: column;
        justify-content: space-around;
        overflow: hidden;
        width: 230px;

        & > div {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .chat-buttons {
        margin-right: 20px;
        display: flex;
        align-items: center;
        flex-shrink: 0;
      }
    }

    .chat-messages {
      padding: 20px;
      overflow-y: auto;
      flex-grow: 1;
    }

    .type-message {
      display: flex;
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 10px;
      flex-shrink: 0;

      img {
        width: 35px;
        height: 35px;
      }

      .input-message {
        background-color: $pale-grey;
      }
    }
  }
}
