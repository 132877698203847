@import "~bootstrap";

.header {
  margin-top: 2em;
  margin-bottom: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include  media-breakpoint-down(md) {
    margin-bottom: 30px;
  }

  .right {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
