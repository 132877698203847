//Bootstrap
.form-label {
  font-weight: bold;
  @include opensans-bold;
}

.dropdown-toggle::before {
  display:none !important;
}

.modal-header .close {
  position: absolute;
  top: 7px;
  right: 10px;
  outline: none;
}

.modal-footer, .modal-header {
  border-top: 0 !important;
  border-bottom: 0 !important;
}

.icheck-primary > input:first-child:not(:checked):not(:disabled):hover + label::before,
.icheck-primary > input:first-child:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
  border-color: $lightish-red;
}

.icheck-primary > input:first-child:checked + label::before,
.icheck-primary > input:first-child:checked + input[type="hidden"] + label::before {
  background-color: $lightish-red;
  border-color: $lightish-red;
}

