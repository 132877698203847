$lightish-red: #ff2a48;
$purple-brown: #402e30;
$brown-grey: #ababab;
$aquamarine: #00e6a1;
$brown-grey-two: #979797;
$pale-grey: #f8f9fa;

$theme-colors: (
  "red": $lightish-red,
  "outline": $lightish-red,
);
