@import "styles/constants";
@import "styles/typography";
@import "styles/custom";
@import "styles/variables";
@import "styles/vendors";

@import "~bootstrap/scss/bootstrap";
@import "~@fortawesome/fontawesome-free/css/all.min.css";

html, body {
  width: 100%;
  height: 100%;
  @include opensans-regular();
  font-size: 14px;
  color: $purple-brown;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
}

#app-root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

h1 {
  font-size: 18px;
  font-weight: bold;
  color: $lightish-red
}

p {
  font-size: 14px;
  color: $purple-brown;
}

a:hover{
  text-decoration: none;
}

a:focus {
  outline: none;
}

video:focus {
  outline: none;
}

.btn {
  font-size: 16px;
}

.btn-primary:hover, .btn-primary:active, .btn-primary:focus{
  color: #FFFFFF !important;
  background-color: $lightish-red !important;
}

.btn-outline-primary:hover, .btn-outline-primary:active, .btn-outline-primary:focus{
  color: $lightish-red !important;
  background-color: white !important;
}

.btn-outline-secondary:hover, .btn-outline-secondary:active, .btn-outline-secondary:focus{
  color: #6c757d !important;
  background-color: white !important;
}

.btn .badge {
  position: relative;
  top: -1px;
  @include opensans-bold;
}

.btn-flat {
  background-color: $lightish-red;
  color: white;
}

.btn-menu {
  background-color: white !important;

  &.btn-menu:after {
    border: 0;
  }
}

hr {
  &.red {
    border-top: 2px solid $lightish-red !important;
  }
}

b {
  @include opensans-bold;
}

.badge-notify{
  @include opensans-regular;
  font-size: 10px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.21);
  background: $lightish-red;
  position: relative;
  color: #FFFFFF;
  top: -15px;
  right: 10px;
}

.badge-notify-button{
  @include opensans-regular;
  font-size: 10px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.21);
  background: $lightish-red;
  position: absolute !important;
  color: #FFFFFF;
  top: -9px !important;
  right: 5px !important;
}


.pagination {
  .page-item {
    margin-left: 7px;
    margin-right: 7px;

    &.disabled {
      .page-link {
        background-color: $lightish-red;
        color: white;
      }
    }
  }

  .page-link {
    color: $purple-brown;
  }
}

//Font-Awesome
.fa-chevron-left, .fa-chevron-right, .fa-briefcase, .fa-map-marker-alt {
  color: $brown-grey;
}

.fa-question-circle {
  color: $lightish-red;
}

.fa-envelope {
  &.brown {
    color: $purple-brown !important;
  }

  &.brown-2 {
    color: #ABABAB !important;
  }

  &.red {
    color: $lightish-red !important;
  }
}

.Select-control {
  border: 1px solid red !important;
  box-shadow: none !important;
}
