@import "../../../styles/constants";

.preview {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid $brown-grey;
  width: 100%;
  cursor: pointer;

  padding: 10px 8px;
  box-sizing: border-box;

  &.selected {
    background-color: $pale-grey;
  }

  .preview-user-image {
    content: '';
    width: 60px;
    height: 60px;
    background: url("../../../assets/images/profile-icon.svg") no-repeat center;
    background-size: cover;
    clip-path: circle(30px at center);
    -webkit-clip-path: circle(30px at center);
  }

  .offline, .online {
    width: 75px;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  .online {
    background-color: #00e6a1;
    clip-path: circle(33px at center);
    -webkit-clip-path: circle(33px at center);
  }

  .preview-user {
    display: flex;
    flex-grow: 1;
    margin-left: 10px;
    flex-direction: column;
    justify-content: space-around;
    overflow: hidden;

    & > div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .username {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
  }

  .preview-message {
    text-align: right;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 5px;
    font-size: 12px;
    color: $brown-grey;
    max-width: 70px;
    margin-left: 10px;

    .message {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
